import React from 'react';

export const StatusOkIcon = () => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="statusOKIconT">Status OK Icon</title>
            <desc id="statusOKIconD">
                A vector illustration of a Status OK icon
            </desc>
            <path
                d="M12.5 13.3334L16.8441 16.5915C17.4021 17.0099 18.1883 16.9277 18.6476 16.4029L27.1667 6.66675"
                stroke="#01ECA5"
                strokeLinecap="round"
            />
            <path
                d="M28.5 16C28.5 18.5073 27.7147 20.9516 26.2541 22.9897C24.7937 25.0279 22.7315 26.5572 20.3573 27.3632C17.9831 28.1691 15.416 28.2111 13.0166 27.4833C10.6172 26.7555 8.50612 25.2943 6.97976 23.3052C5.4534 21.316 4.58847 18.8987 4.50643 16.3927C4.42439 13.8867 5.12937 11.4179 6.52236 9.33316C7.91536 7.2484 9.92639 5.65234 12.273 4.76914C14.6196 3.88592 17.184 3.75995 19.6059 4.4089"
                stroke="#01ECA5"
                strokeLinecap="round"
            />
        </svg>
    );
};
