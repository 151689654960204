export const CeoIcon = () => {
    return (
        <svg
            width="38"
            height="41"
            viewBox="0 0 38 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="ceoT">Ceo</title>
            <desc id="ceoD">A vector illustration of a ceo representative</desc>
            <path
                d="M10.537 12.376c.26 3.405 1.614 1.938 1.893 3.43.465 2.48 2.661 4.275 4.028 4.895a6.18 6.18 0 0 0 2.483.557h.018a6.18 6.18 0 0 0 2.482-.557c1.367-.62 3.564-2.414 4.028-4.895.28-1.492 1.634-.024 1.893-3.43 0-1.357-.739-1.695-.739-1.695s.376-2.009.523-3.555C27.33 5.2 26.022.224 19.05.224l-.088.002h-.024l-.089-.002c-6.97 0-8.278 4.976-8.095 6.902.147 1.546.523 3.555.523 3.555s-.74.338-.74 1.695ZM34.763 29.094c-3.516-1.79-8.612-4.023-8.857-4.102l-.007-.002a1.37 1.37 0 0 0-1.75.837l-.003-.002c-.908 2.187-2.575 6.085-3.61 7.965l-.25-5.27s1.555-3.3 1.726-3.602c.4-.708.04-1.311-.712-1.311H16.7c-.75 0-1.111.603-.71 1.311.17.303 1.724 3.602 1.724 3.602l-.25 5.27c-1.034-1.88-2.702-5.778-3.609-7.965-.001 0-.002 0-.003.002a1.37 1.37 0 0 0-1.75-.837c-.002 0-.004 0-.007.002-.245.079-5.341 2.313-8.857 4.102-1.58.803-2.532 1.802-2.532 2.854v8.276h36.59v-8.276c0-1.052-.953-2.051-2.532-2.854Z"
                fill="#0048AA"
            />
        </svg>
    );
};
