import React from 'react';

import { Location } from 'components/location/Location';

import styles from 'sections/hero/hero.module.css';

export const LocationHero = () => {
    return (
        <div className={styles.hero__location}>
            <Location />
        </div>
    );
};
