import { useEffect } from 'react';

import { IS_MOBILE } from 'constants/media';

export const useResizeButtons = () => {
    useEffect(() => {
        const callback: MutationCallback = mutationsList => {
            for (const mutation of mutationsList) {
                if (
                    mutation.type === 'childList' ||
                    mutation.type === 'attributes'
                ) {
                    const buttons = document.querySelectorAll(
                        '.gm-ui-hover-effect'
                    ) as NodeListOf<HTMLElement>;

                    buttons.forEach(button => {
                        button.style.width = IS_MOBILE ? '36px' : '46px';
                        button.style.height = '36px';
                    });
                }
            }
        };

        const observer = new MutationObserver(callback);

        observer.observe(document.body, {
            attributes: true,
            childList: true,
            subtree: true,
        });

        return () => observer.disconnect();
    }, []);
};
