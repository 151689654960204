type TGetImageUrl = (_src: string | undefined) => string | undefined;
export const getImgSrc2x: TGetImageUrl = imageUrl => {
    return imageUrl?.replace('%401x', '%402x');
};

export const getImgSrc3x: TGetImageUrl = imageUrl => {
    return imageUrl?.replace('%401x', '%403x');
};

export const getImgSrcWebp: TGetImageUrl = imageUrl => {
    return imageUrl?.replace('png', 'webp');
};

// refactor
export const getMobileSrc: TGetImageUrl = imageUrl => {
    return imageUrl?.replace('424x275', '320x220');
};
