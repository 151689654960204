import {
    getImgSrc2x,
    getImgSrc3x,
    getImgSrcWebp,
} from 'utils/imageResize.utils';

import { BunkerSupplySpinningText } from 'components/vectors/bunkerSupplySpinningText/BunkerSupplySpinningText';

import styles from 'sections/aboutUs/aboutUs.module.css';

export const AboutUsMedia = () => {
    const desktopSrc =
        'https://sni-general.s3.amazonaws.com/abousUs/abousUs%401x.png';

    const desktopSrc2x = getImgSrc2x(desktopSrc);
    const desktopSrc3x = getImgSrc3x(desktopSrc);

    const desktopSrcWebp = getImgSrcWebp(desktopSrc);
    const desktopSrcWebp2x = getImgSrc2x(desktopSrcWebp);
    const desktopSrcWebp3x = getImgSrc3x(desktopSrcWebp);

    const mobileSrc =
        'https://sni-general.s3.amazonaws.com/abousUs/aboutUsMobile%401x.png';

    const mobileSrc2x = getImgSrc2x(mobileSrc);
    const mobileSrc3x = getImgSrc3x(mobileSrc);

    const mobileSrcWebp = getImgSrcWebp(mobileSrc);
    const mobileSrcWebp2x = getImgSrc2x(mobileSrcWebp);
    const mobileSrcWebp3x = getImgSrc3x(mobileSrcWebp);

    return (
        <div className={styles.aboutUs__media}>
            <div className={styles.aboutUs__pic}>
                <picture>
                    <source
                        type="image/webp"
                        media="(max-width: 600px)"
                        srcSet={`${mobileSrcWebp} 1x, ${mobileSrcWebp2x} 2x, ${mobileSrcWebp3x} 3x`}
                        sizes="(max-width: 600px) 100vw"
                    />
                    <source
                        type="image/png"
                        media="(max-width: 600px)"
                        srcSet={`${mobileSrc} 1x,  
                            ${mobileSrc2x} 2x, 
                            ${mobileSrc3x} 3x`}
                        sizes="(max-width: 600px) 100vw"
                    />

                    <source
                        type="image/webp"
                        srcSet={`${desktopSrcWebp} 1x, ${desktopSrcWebp2x} 2x, ${desktopSrcWebp3x} 3x`}
                        sizes="100vw"
                    />

                    <img
                        src={desktopSrc}
                        srcSet={`${desktopSrc2x} 2x, ${desktopSrc3x} 3x`}
                        sizes="100vw"
                        alt="bunker supply"
                        width="100%"
                        height="100%"
                    />
                </picture>
            </div>

            <BunkerSupplySpinningText />
        </div>
    );
};
