import React from 'react';

import { BurgerMenu } from 'components/burgerMenu/BurgerMenu';
import { LogoMobile } from 'components/vectors/LogoMobile';

import styles from 'sections/hero/hero.module.css';

export const HeaderMobile = () => {
    return (
        <header className={styles.hero__header}>
            <div className={styles.hero__headerContainer}>
                <div className={styles.hero__logoMobile}>
                    <LogoMobile />
                </div>

                <BurgerMenu />
            </div>
        </header>
    );
};
