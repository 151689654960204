export const ArrowRight = () => {
    return (
        <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="arrowDownT">Arrow right</title>
            <desc id="arrowDownD">A vector illustration of an arrow right</desc>
            <path
                d="M.71 13.293a1 1 0 0 0 1.415 0L7.013 8.4a2 2 0 0 0-.001-2.828L2.122.683A1 1 0 1 0 .707 2.097l4.186 4.185a1 1 0 0 1 0 1.415L.71 11.879a1 1 0 0 0 0 1.414Z"
                fill="#0F0F0F"
            />
        </svg>
    );
};
