import React from 'react';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

import styles from 'components/animatedPart/animatedPart.module.css';

interface AnimatedPartProps {
    children: React.ReactNode;
    delay?: string;
    className?: string;
    isDecoration?: boolean;
}

export const AnimatedPart: React.FC<AnimatedPartProps> = ({
    children,
    delay,
    className,
    isDecoration,
}) => {
    const { ref, isVisible } = useIntersectionObserver();

    const transitionDelay = (isDecoration ? '0.2s' : delay) || 'none';

    const getClassname = isDecoration
        ? `${styles.hiddenDecoration} ${isVisible ? styles.visibleDecoration : ''}`
        : `${styles.hidden} ${isVisible ? styles.visible : ''}`;

    return (
        <div
            ref={ref}
            className={`${getClassname} ${className}`}
            style={{
                transitionDelay,
            }}
        >
            {children}
        </div>
    );
};
