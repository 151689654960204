export const getNotificationTextData = () => {
    return {
        success: {
            titleText: 'Message successfully sent',
            descText: 'We will contact you shortly',
        },
        error: {
            titleText: 'There was an error',
            descText: 'Please try again later',
        },
        emailConnectionError: {
            titleText: 'The email service is temporarily unavailable',
            descText: 'Please try again later',
        },
    };
};
