export const BigShip = () => {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="bigShipT">Big ship</title>
            <desc id="bigShipD">A vector illustration of a big ship icon</desc>
            <path d="m26.5 29.75-5.75 1.875v2L26.5 32v-2.25Z" fill="#021E43" />
            <path d="m26.5 32-5.75 1.625v2.25l5.75-1.625V32Z" fill="#3E4347" />
            <path
                d="M37.75 19.5h-.125c.125-.375.125-.75.125-1.125 0-2.125-1.5-4-3.625-4.375-.625-2.5-2.875-4.375-5.5-4.375-.75 0-1.625.125-2.25.5-1.25-1.875-3.25-3-5.625-3H20C18.75 4.375 16 2.5 12.75 2.5c-4.375 0-7.875 3.5-7.875 8s3.5 8 8 8c.875 0 1.75-.125 2.5-.375 1.25 1.625 3.25 2.75 5.375 2.75 1.5 0 2.875-.5 4-1.375 1 1 2.375 1.625 3.875 1.625.25 0 .5 0 .75-.125.75 1.25 2.25 2 3.75 2 .875 0 1.625-.25 2.375-.625a2.29 2.29 0 0 0 2.25 1.75c1.25 0 2.25-1 2.25-2.25 0-1.375-1-2.375-2.25-2.375Z"
                fill="#DAE3EA"
            />
            <path
                d="M42.125 22.75c-1.375-.625-2.875-.875-4.375-.875-1.75 0-4.125.375-5.625 1.125L31 32h15.875l-4.75-9.25Z"
                fill="#94989B"
            />
            <path
                d="m23 34.25-5.625 2.625v6.5h3.375V57h29.5V42.25l-27.25-8Z"
                fill="#62727A"
            />
            <path
                d="M23 34.25v6.875h3.375v14.75h29.5v-14.75h3.5V34.25H23Z"
                fill="#B2C1C0"
            />
            <path
                d="M26.375 59.375 12.75 61.5V50.125l13.625-6.75v16Z"
                fill="#62727A"
            />
            <path
                d="M70.375 71.125s-38.5 8.125-56.625 1.5c-2.5-.875-5.375-2.5-8.875-6.125V61S17.75 59.375 33.5 50.875C52.875 40.5 69.875 40 75.375 42.375c-.125-.125-5 7.75-5 28.75Z"
                fill="#0048AA"
            />
            <path
                d="M70.625 64.875c.125-4.75 1.625-6.75 3.5-9.875C81 44 75.25 42.375 75.25 42.375c-4.375 7.25-4.625 22.5-4.625 22.5Z"
                fill="#021E43"
            />
            <path
                d="M74.125 44.625c-7-2-23.125-.5-40.875 9-15.5 8.375-28.25 9-28.375 9v1.125c.125 0 13.125-.625 28.875-9C51 45.5 67 44 73.75 45.75l.375-1.125Z"
                fill="#fff"
            />
            <path
                d="M74.125 44.75c-.125.375-.25.75-.375 1 1 .375 2.75 1.375 3.375 3.25.25-.625.375-1.25.375-1.75-1-1.375-2.5-2.125-3.375-2.5Z"
                fill="#B2C1C0"
            />
            <path d="M26.5 32h29.375v2.25H26.5V32Z" fill="#62727A" />
            <path d="M26.5 29.75h29.375V32H26.5v-2.25Z" fill="#0048AA" />
            <path d="M23 34.25h36.375V41H23v-6.75Z" fill="#C5D0D8" />
            <path
                d="M25.375 36H27v3.375h-1.625V36ZM29.625 36h1.625v3.375h-1.625V36ZM33.875 36H35.5v3.375h-1.625V36ZM38.125 36h1.625v3.375h-1.625V36ZM42.5 36h1.625v3.375H42.5V36ZM46.75 36h1.625v3.375H46.75V36ZM51 36h1.625v3.375H51V36ZM55.25 36h1.625v3.375H55.25V36Z"
                fill="#94989B"
            />
            <path
                d="M28.625 42.25h4.5v2.25h-4.5v-2.25ZM53.625 42.875l-4.5 1.25V42.25h4.5v.625ZM42.25 42.25h4.5v2.25h-4.5v-2.25ZM35.5 42.25H40v2.25h-4.5v-2.25Z"
                fill="#62727A"
            />
            <path
                d="M16.875 51.75 14 53.125v2.75l2.875-1.125v-3ZM25.125 47.625l-2.875 1.5v3.5l2.875-1.125v-3.875ZM21 49.75l-2.875 1.375v3.125L21 53.125V49.75Z"
                fill="#fff"
            />
        </svg>
    );
};
