import React from 'react';
import { Element } from 'react-scroll';

import { AnimatedPart } from 'components/animatedPart/AnimatedPart';
import { CeoIcon } from 'components/vectors/CeoIcon';
import { Mail } from 'components/vectors/Mail';
import { SalesManagerIcon } from 'components/vectors/SalesManagerIcon';
import { IS_MOBILE } from 'constants/media';
import { InfoDecoration } from 'sections/team/InfoDecoration';
import { getDelay } from 'sections/team/team.utils';

import styles from 'sections/team/team.module.css';
import sharedStyles from 'sharedStyles/sharedStyles.module.css';

const teamInfo = [
    {
        aboutText:
            'Igor the founder and CEO of our company, a visionary leader with a profound understanding of the market. With over 20 years of experience in the technology and management sectors, Igor successfully leads a team of professionals, driving innovative solutions what meet the highest standards of quality.',
        icon: <CeoIcon />,
        name: 'Igor',
        position: 'CEO',
        email: 'industry@sinergetic.ae',
        emailText: 'industry@sinergetic.ae',
    },
    {
        aboutText:
            'Denis, our Senior Bunker Trader, is a seasoned professional with 15 years of experience in bunkers, encompassing both trading and physical supply. Residing in Istanbul, Denis plays a pivotal role in driving our operations and ensuring we deliver exceptional service to our clients.',
        icon: <SalesManagerIcon />,
        name: 'Denis',
        position: 'Senior Bunker Trader',
        email: 'bunkers@sinergetic.ae',
        emailText: 'bunkers@sinergetic.ae',
    },
];
export const Team = () => {
    return (
        <section className={styles.team}>
            <Element name="ourTeam" className={styles.team__container}>
                <div
                    className={`${sharedStyles.titleBox} ${styles.team__titleMb32}`}
                >
                    <h3 className={styles.team__title}>our team</h3>
                    <AnimatedPart isDecoration={true}>
                        <div className={sharedStyles.title__decoration}></div>
                    </AnimatedPart>
                </div>

                <div className={styles.team__info}>
                    {teamInfo.map(
                        (
                            {
                                aboutText,
                                icon,
                                name,
                                position,
                                email,
                                emailText,
                            },
                            index
                        ) => {
                            return (
                                <div className={styles.info__row} key={name}>
                                    <AnimatedPart
                                        delay={getDelay(index * 3 + 1)}
                                        className={`${styles.info} ${styles.info_bgBlue} ${styles.info_about}`}
                                    >
                                        <div>
                                            <div
                                                className={`${sharedStyles.titleBox} ${styles.info__titleMb16}`}
                                            >
                                                <h4
                                                    className={
                                                        styles.info__title
                                                    }
                                                >
                                                    About
                                                </h4>
                                                <InfoDecoration />
                                            </div>
                                            <p className={styles.info__text}>
                                                {aboutText}
                                            </p>
                                        </div>
                                    </AnimatedPart>

                                    <AnimatedPart
                                        delay={getDelay(index * 3 + 2)}
                                        className={styles.info}
                                    >
                                        <div>
                                            <div
                                                className={
                                                    styles.info__container
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.info__icon
                                                    }
                                                >
                                                    {icon}
                                                </div>
                                                <h5
                                                    className={
                                                        styles.info__name
                                                    }
                                                >
                                                    {name}
                                                </h5>
                                                <span
                                                    className={
                                                        styles.info__position
                                                    }
                                                >
                                                    {position}
                                                </span>
                                            </div>
                                        </div>
                                    </AnimatedPart>

                                    <AnimatedPart
                                        delay={getDelay(index * 3 + 3)}
                                        className={`${styles.info} ${styles.info_bgBlueContacts}`}
                                    >
                                        <div>
                                            <div
                                                className={
                                                    styles.info__container
                                                }
                                            >
                                                <div
                                                    className={`${styles.info__titleBox} ${styles.info__titleMb16}`}
                                                >
                                                    <h4
                                                        className={
                                                            styles.info__title
                                                        }
                                                    >
                                                        contacts
                                                    </h4>
                                                    <InfoDecoration />
                                                </div>
                                                <a
                                                    type="mail"
                                                    href={`mailto:${email}`}
                                                    className={
                                                        styles.info__contacts
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.contacts__icon
                                                        }
                                                    >
                                                        <Mail />
                                                    </div>
                                                    {emailText}
                                                </a>
                                            </div>
                                        </div>
                                    </AnimatedPart>

                                    {IS_MOBILE && index === 0 && (
                                        <div className={styles.divider}></div>
                                    )}
                                </div>
                            );
                        }
                    )}
                </div>
            </Element>
        </section>
    );
};
