import React from 'react';

import { AnimatedPart } from 'components/animatedPart/AnimatedPart';

import sharedStyles from 'sharedStyles/sharedStyles.module.css';

export const InfoDecoration = () => {
    return (
        <AnimatedPart isDecoration={true}>
            <div className={sharedStyles.title__decoration}></div>
        </AnimatedPart>
    );
};
