import React from 'react';
import { Link } from 'react-scroll';

import { Menu } from 'components/menu/Menu';
import { Logo } from 'components/vectors/Logo';
import { LocationHero } from 'sections/hero/LocationHero';

import styles from 'sections/hero/hero.module.css';

export const Header = () => {
    return (
        <header className={styles.navigation}>
            <Link
                className={styles.hero__logo}
                to="home"
                smooth={true}
                duration={800}
                spy={true}
            >
                <Logo />
            </Link>

            <Menu />
            <LocationHero />
        </header>
    );
};
