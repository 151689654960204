import React, { useState } from 'react';

import { Footer } from 'components/footer/Footer';
import { AboutUs } from 'sections/aboutUs/AboutUs';
import { ContactUs } from 'sections/contactUs/ContactUs';
import { Hero } from 'sections/hero/Hero';
import { Services } from 'sections/services/Services';
import { Team } from 'sections/team/Team';
import { WeOperateIn } from 'sections/weOperateIn/WeOperateIn';

import styles from 'index.module.css';

function App() {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isBgImgLoaded, setIsBgImgLoaded] = useState(false);

    return (
        <div className={styles.app}>
            <Hero
                isVideoLoaded={isVideoLoaded}
                setIsVideoLoaded={setIsVideoLoaded}
                isBgImgLoaded={isBgImgLoaded}
                setIsBgImgLoaded={setIsBgImgLoaded}
            />
            <div className={styles.container}>
                <AboutUs />
                <WeOperateIn />
                <Services />
                <Team />
                <ContactUs />
            </div>
            <Footer />
        </div>
    );
}

export default App;
