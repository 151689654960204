import React, { Dispatch, SetStateAction } from 'react';
import { Element, Link } from 'react-scroll';

import { AnimatedPart } from 'components/animatedPart/AnimatedPart';
import { ArrowDown } from 'components/vectors/ArrowDown';
import { VideoLoader } from 'components/videoLoader/VideoLoader';
import { IS_DESKTOP, IS_MOBILE, IS_TABLET } from 'constants/media';
import { Header } from 'sections/hero/Header';
import { HeaderMobile } from 'sections/hero/HeaderMobile';
import { HeroMainImg } from 'sections/hero/HeroMainImg';

import styles from 'sections/hero/hero.module.css';

interface IHeroProps {
    isVideoLoaded: boolean;
    setIsVideoLoaded: Dispatch<SetStateAction<boolean>>;
    isBgImgLoaded: boolean;
    setIsBgImgLoaded: Dispatch<SetStateAction<boolean>>;
}

export const Hero = ({
    isVideoLoaded,
    setIsVideoLoaded,
    isBgImgLoaded,
    setIsBgImgLoaded,
}: IHeroProps) => {
    return (
        <section className={styles.hero}>
            <Element name="home">
                {IS_DESKTOP && (
                    <>
                        <VideoLoader
                            videoUrl="https://d2wsvyffy8wgwm.cloudfront.net/footage.mp4"
                            setIsVideoLoaded={setIsVideoLoaded}
                            isVideoLoaded={isVideoLoaded}
                        />

                        {isVideoLoaded && <Header />}
                    </>
                )}

                {IS_TABLET && (
                    <div className={styles.mainPictureBackground}>
                        <HeroMainImg
                            imgUrl="https://sni-general.s3.amazonaws.com/hero/ipad-boat%401x.png"
                            setIsBgImgLoaded={setIsBgImgLoaded}
                        />
                        {isBgImgLoaded && <Header />}
                    </div>
                )}

                {IS_MOBILE && (
                    <div className={styles.mainPictureBackground}>
                        <HeroMainImg
                            imgUrl="https://sni-general.s3.amazonaws.com/hero/mobile-boat%401x.png"
                            setIsBgImgLoaded={setIsBgImgLoaded}
                        />
                        {isBgImgLoaded && (
                            <>
                                <HeaderMobile />
                                <div className={styles.hero__heading}>
                                    <AnimatedPart>
                                        <h1>Ship bunkering</h1>
                                    </AnimatedPart>

                                    <AnimatedPart delay="0.2s">
                                        <span>worldwide</span>
                                    </AnimatedPart>
                                </div>
                                <div className={styles.hero__arrowDown}>
                                    <AnimatedPart delay="0.3s">
                                        <Link
                                            to="aboutUs"
                                            smooth={true}
                                            duration={800}
                                            spy={true}
                                        >
                                            <ArrowDown />
                                        </Link>
                                    </AnimatedPart>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </Element>
        </section>
    );
};
