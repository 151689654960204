import React, { useState } from 'react';

import { Menu } from 'components/menu/Menu';
import { LocationHero } from 'sections/hero/LocationHero';

import styles from 'components/burgerMenu/burgerMenu.module.css';

export const BurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => setIsOpen(!isOpen);
    const burgerContainerStyles = isOpen
        ? `${styles.burgerContainerOpen}`
        : `${styles.burgerContainer}`;
    const burgerStyles = isOpen ? `${styles.cross}` : `${styles.burger}`;

    return (
        <div className={styles.container}>
            <div className={burgerContainerStyles} onTouchStart={toggleMenu}>
                <div className={burgerStyles}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            {isOpen && (
                <div className={styles.fullScreenMenu}>
                    <Menu handleClick={toggleMenu} />
                    <LocationHero />
                </div>
            )}
        </div>
    );
};
