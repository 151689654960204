import {
    getImgSrc2x,
    getImgSrc3x,
    getImgSrcWebp,
} from 'utils/imageResize.utils';

import styles from 'sections/hero/hero.module.css';

interface IHeroMainImgProps {
    imgUrl: string;
    setIsBgImgLoaded: (_v: boolean) => void;
}

export const HeroMainImg = ({
    imgUrl,
    setIsBgImgLoaded,
}: IHeroMainImgProps) => {
    const imgSrc2x = getImgSrc2x(imgUrl);
    const imgSrc3x = getImgSrc3x(imgUrl);

    const imgSrcWebp = getImgSrcWebp(imgUrl);
    const imgSrcWebp2x = getImgSrc2x(imgSrcWebp);
    const imgSrcWebp3x = getImgSrc3x(imgSrcWebp);
    const handleImgLoad = () => setIsBgImgLoaded(true);

    return (
        <picture className={styles.mainPicture}>
            <source
                type="image/webp"
                srcSet={`${imgSrcWebp} 1x, ${imgSrcWebp2x} 2x, ${imgSrcWebp3x} 3x`}
                sizes="100vw"
            />

            <img
                className={styles.mainImage}
                src={imgUrl}
                srcSet={`${imgSrc2x} 2x, ${imgSrc3x} 3x`}
                sizes="100vw"
                alt="big ship in the sea"
                onLoad={handleImgLoad}
            />
        </picture>
    );
};
