import React, { useEffect, useRef, useState } from 'react';

import { BigShip } from 'components/vectors/loader/BigShip';
import { SmallShip } from 'components/vectors/loader/SmallShip';
import { getStartPicPositionRight } from 'components/videoLoader/videoLoader.util';

import styles from 'components/videoLoader/videoLoader.module.css';

interface VideoLoaderProps {
    videoUrl: string;
    setIsVideoLoaded: (_v: boolean) => void;
    isVideoLoaded: boolean;
}

export const VideoLoader: React.FC<VideoLoaderProps> = ({
    videoUrl,
    setIsVideoLoaded,
    isVideoLoaded,
}) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [loadedPercentage, setLoadedPercentage] = useState<number>(0);
    const [canPlayThrough, setCanPlayThrough] = useState<boolean>(false);
    const fullyLoadedVideoPercent = 100;
    const delayBeforeVideoShow = 400;

    useEffect(() => {
        const video = videoRef.current;

        const handleProgress = () => {
            if (video && video.buffered.length > 0) {
                const bufferedEnd = video.buffered.end(
                    video.buffered.length - 1
                );
                const duration = video.duration;

                if (duration > 0) {
                    setLoadedPercentage(prev => {
                        const newPercentage = (bufferedEnd / duration) * 100;
                        return prev !== fullyLoadedVideoPercent
                            ? newPercentage
                            : fullyLoadedVideoPercent;
                    });
                }
            }
        };

        const handleCanPlayThrough = () => {
            setCanPlayThrough(true);
            setLoadedPercentage(fullyLoadedVideoPercent);
        };

        if (video) {
            video.addEventListener('progress', handleProgress);
            video.addEventListener('canplaythrough', handleCanPlayThrough);

            return () => {
                video.removeEventListener('progress', handleProgress);
                video.removeEventListener(
                    'canplaythrough',
                    handleCanPlayThrough
                );
            };
        }
    }, [loadedPercentage]);

    useEffect(() => {
        const shouldShowAVideo =
            loadedPercentage === fullyLoadedVideoPercent && canPlayThrough;

        if (shouldShowAVideo) {
            const timeout = setTimeout(() => {
                setIsVideoLoaded(true);
            }, delayBeforeVideoShow);

            return () => clearTimeout(timeout);
        }
    }, [loadedPercentage, canPlayThrough, setIsVideoLoaded]);

    const startPicPositionRight = getStartPicPositionRight(loadedPercentage);

    return (
        <div className={styles.videoBackground}>
            {!isVideoLoaded && (
                <div className={styles.loaderContainer}>
                    <div className={styles.progressBar__container}>
                        <div
                            className={styles.progressBar}
                            style={{
                                width: `${loadedPercentage}%`,
                            }}
                        >
                            <div
                                className={styles.progressBar__startPic}
                                style={{ right: startPicPositionRight }}
                            >
                                <SmallShip />
                            </div>
                        </div>
                        <div className={styles.progressBar__endPic}>
                            <BigShip />
                        </div>
                    </div>
                </div>
            )}

            <video ref={videoRef} muted autoPlay loop className={styles.video}>
                <source src={videoUrl} type="video/mp4" />
                <img
                    src="https://sni-general.s3.amazonaws.com/hero/desktop-boat%401x.png"
                    alt="bunker supply"
                />
            </video>
        </div>
    );
};
