export const LocationIcon = ({ fill }: { fill?: string }) => {
    const color = fill || '#0048AA';

    return (
        <svg
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="locationPinT">Location icon</title>
            <desc id="locationPinD">
                A vector illustration of a location icon
            </desc>
            <path
                d="M8.5 19c3.5-3.6 7-6.824 7-10.8 0-3.976-3.134-7.2-7-7.2s-7 3.224-7 7.2S5 15.4 8.5 19Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
