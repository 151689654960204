export const Mail = () => {
    return (
        <svg
            width="25"
            height="20"
            viewBox="0 0 25 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="mailT">Mail</title>
            <desc id="mailD">A vector illustration of a mail</desc>

            <path
                d="M24.438 3.487a3.32 3.32 0 0 0-.913-1.707A3.322 3.322 0 0 0 21.17.805H3.829A3.326 3.326 0 0 0 .565 3.487a3.22 3.22 0 0 0-.065.647v12.18c0 .467.1.916.276 1.322.164.387.405.737.7 1.03.074.075.148.144.229.212.576.477 1.32.765 2.124.765h17.342a3.305 3.305 0 0 0 2.354-.976 3.33 3.33 0 0 0 .703-1.031v-.003c.176-.406.272-.852.272-1.32V4.134c0-.219-.021-.436-.062-.646ZM2.68 2.985a1.608 1.608 0 0 1 1.15-.477h17.34a1.602 1.602 0 0 1 1.296.647l-9.09 7.922a1.331 1.331 0 0 1-1.753 0L2.539 3.152c.04-.059.09-.114.142-.167Zm-.477 13.329v-11.4l6.578 5.739-6.575 5.732c-.003-.022-.003-.047-.003-.071Zm18.968 1.625H3.829c-.294 0-.57-.077-.805-.213l6.937-6.045.647.563c.542.471 1.22.71 1.892.71a2.89 2.89 0 0 0 1.895-.71l.648-.563 6.933 6.045c-.235.136-.51.213-.805.213Zm1.626-1.625c0 .024 0 .049-.003.07l-6.575-5.728 6.578-5.739v11.397Z"
                fill="#000"
            />
        </svg>
    );
};
