import { IS_MOBILE } from 'constants/media';

export const getDelay = (index: number): string => {
    const mobileDelay = '0.2s';
    const delayStep = 0.4;

    if (IS_MOBILE) {
        return mobileDelay;
    }
    return index * delayStep + 's';
};
