import { useEffect, useState } from 'react';

import {
    defaultAnimationData,
    mobileAnimationData,
    tabletAnimationData,
} from 'components/vectors/bunkerSupplySpinningText/constants';
import { IS_DESKTOP, IS_MOBILE, IS_TABLET } from 'constants/media';

import styles from 'components/vectors/bunkerSupplySpinningText/bunkerSupplySpinningText.module.css';

export const BunkerSupplySpinningText = () => {
    const [animationData, setAnimationData] = useState(defaultAnimationData);

    useEffect(() => {
        if (IS_DESKTOP) {
            setAnimationData(defaultAnimationData);
        }
        if (IS_TABLET) {
            setAnimationData(tabletAnimationData);
        }
        if (IS_MOBILE) {
            setAnimationData(mobileAnimationData);
        }
    }, []);

    const { width, pathD } = animationData;
    const minAnimationWidth = IS_MOBILE
        ? window.innerWidth - 32
        : Number(width) + 50;

    return (
        <div style={{ minWidth: `${minAnimationWidth}px` }}>
            <svg
                id={styles.rotatingCircleText}
                viewBox={`0 0 ${width} ${width}`}
                width={width}
                height={width}
                fill="#0048AA"
            >
                <defs>
                    <path id="circle" d={pathD}></path>
                </defs>
                <text startOffset="0">
                    <textPath xlinkHref="#circle" className={styles.text}>
                        {IS_DESKTOP && (
                            <>
                                Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                        )}
                        {IS_TABLET && (
                            <>
                                Bunker supply&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                        )}
                        {IS_MOBILE && (
                            <>
                                Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bunker
                                supply&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                        )}
                    </textPath>
                </text>
            </svg>
        </div>
    );
};
