type MarkerType = {
    name: string;
    coordinates: [number, number];
};
export const markers: MarkerType[] = [
    {
        name: 'New York/New Jersey',
        coordinates: [-82.040666504, 46.667663996],
    },
    {
        name: 'Jordjia',
        coordinates: [-85.718416, 34.924937],
    },
    {
        name: 'Santos',
        coordinates: [-51.358397, -23.024818],
    },
    {
        name: 'Panama',
        coordinates: [-84.510616, 16.966246],
    },
    {
        name: 'Los Angeles',
        coordinates: [-129.291964, 40.860528],
    },
    {
        name: 'Gibraaltar',
        coordinates: [-12.41657, 43.115691],
    },
    {
        name: 'Valencia',
        coordinates: [-4.302116, 46.411632],
    },
    {
        name: 'Rotterdam',
        coordinates: [-3.139185, 61.062635],
    },
    {
        name: 'Istanbul',
        coordinates: [24.716657, 48.966908],
    },
    {
        name: 'Jeddah',
        coordinates: [34.119139, 26.494213],
    },
    {
        name: 'Jebel Ali',
        coordinates: [51.942404, 32.965679],
    },
    {
        name: 'Mumbai',
        coordinates: [68.792609, 23.992352],
    },
    {
        name: 'Colachel',
        coordinates: [73.144856, 14.959942],
    },
    {
        name: 'Klang Malaysia',
        coordinates: [96.358161, 10.001962],
    },
    {
        name: 'Singapore',
        coordinates: [99.855923, 7.255993],
    },
    {
        name: 'Nahodka',
        coordinates: [136.973628, 50.815899],
    },
    {
        name: 'Vladivostok',
        coordinates: [134.973628, 51.091219],
    },
    {
        name: 'Hedlen',
        coordinates: [109.606872, -13.305028],
    },
    {
        name: 'Wollongong',
        coordinates: [142.494441, -27.886503],
    },
    {
        name: 'Adelaide',
        coordinates: [128.479698, -28.82862],
    },
    {
        name: 'Esperance',
        coordinates: [116.877577, -27.032174],
    },
    {
        name: 'Townsville',
        coordinates: [140.144574, -12.219951],
    },
    {
        name: 'Willington',
        coordinates: [156.479235, -35.840404],
    },
];
