import React from 'react';

import { LocationIcon } from 'components/vectors/LocationIcon';

import styles from 'components/location/location.module.css';

export const Location = ({ color }: { color?: string }) => {
    return (
        <div className={styles.location}>
            <LocationIcon fill={color} />
            <span>Dubai</span>
        </div>
    );
};
