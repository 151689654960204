export const defaultAnimationData = {
    width: '455',
    pathD: ' M 227.5, 227.5 m -190, 0 a 190,190 0 1,1 380,0 a 190,190 0 1,1 -380,0 ',
};

export const tabletAnimationData = {
    width: '415',
    pathD: ' M 207.5, 207.5 m -170, 0 a 170,170 0 1,1 340,0 a 170,170 0 1,1 -340,0 ',
};

export const mobileAnimationData = {
    width: '360',
    pathD: ' M 180, 180 m -140, 0 a 140,140 0 1,1 280,0 a 140,140 0 1,1 -280,0 ',
};
