import { Link } from 'react-scroll';

import styles from 'components/menu/menu.module.css';

interface IMenuProps {
    handleClick?: () => void;
}

export const Menu = ({ handleClick }: IMenuProps) => {
    const menuItems = [
        { id: 1, title: 'main', link: 'home' },
        { id: 2, title: 'about us', link: 'aboutUs' },
        { id: 3, title: 'Ports and Cities We Operate In', link: 'weOperateIn' },
        { id: 4, title: 'services', link: 'services' },
        { id: 5, title: 'our team', link: 'ourTeam' },
        { id: 6, title: 'contact us', link: 'contactUsForm' },
    ];

    return (
        <nav className={styles.menu}>
            <ul className={styles.menuList}>
                {menuItems.map(({ id, link, title }) => (
                    <li key={id} className={styles.menuItem}>
                        <Link
                            to={link}
                            className={styles.menuLink}
                            activeClass={styles.active}
                            smooth={true}
                            duration={800}
                            spy={true}
                            saveHashHistory={true}
                            onClick={handleClick}
                        >
                            {title}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};
