import * as Yup from 'yup';

export const useContactFormSchema = () => {
    const emailRegEx = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

    return Yup.object().shape({
        fullName: Yup.string()
            .required('Field is required')
            .min(2, 'Minimum 2 symbols'),
        companyName: Yup.string(),
        phoneNumber: Yup.string().required('Field is required').max(15),
        email: Yup.string()
            .email('Invalid format')
            .required('Field is required')
            .matches(emailRegEx, 'Invalid format'),
        message: Yup.string()
            .required('Field is required')
            .min(10, 'Minimum 10 symbols'),
    });
};
