import {
    ChangeEvent,
    FC,
    InputHTMLAttributes,
    TextareaHTMLAttributes,
    useState,
} from 'react';
import PhoneInput, { Value as E164Number } from 'react-phone-number-input';
import { FieldHookConfig, useField } from 'formik';

import styles from 'components/contactForm/contactForm.module.css';

type PhoneType = E164Number | undefined;

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    component: 'input';
}

interface CustomTextareaProps
    extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label: string;
    component: 'textarea';
}

interface CustomPhoneNumberFieldProps {
    label: string;
    component: 'phoneNumber';
}

type CustomFieldProps = (
    | CustomInputProps
    | CustomTextareaProps
    | CustomPhoneNumberFieldProps
) &
    FieldHookConfig<string>;

export const CustomField: FC<CustomFieldProps & FieldHookConfig<string>> = ({
    label,
    component = 'input',
    ...props
}) => {
    const [field, meta, helpers] = useField(props);

    const [hasContent, setHasContent] = useState(false);

    const inputStyles = `${styles.input} ${hasContent ? styles.input_filled : null}`;

    const getErrorInputStyles = (error: string | undefined) => {
        if (error && meta.touched) {
            return {
                border: `1px solid red`,
            };
        }
    };
    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        field.onChange(e);
        setHasContent(e.target.value.length > 0);
    };

    const commonProps = {
        ...field,
        ...props,
        className: inputStyles,
        onChange: handleChange,
    };

    const { setValue } = helpers;
    const handlePhoneChange = (value: PhoneType) => {
        value && setValue(value);
        setHasContent(!!value);
    };

    return (
        <>
            <label htmlFor={props.name}>{label}</label>
            {component === 'input' && (
                <input
                    {...(commonProps as InputHTMLAttributes<HTMLInputElement>)}
                    style={getErrorInputStyles(meta.error)}
                />
            )}
            {component === 'textarea' && (
                <textarea
                    rows={3}
                    {...(commonProps as TextareaHTMLAttributes<HTMLTextAreaElement>)}
                    style={getErrorInputStyles(meta.error)}
                />
            )}
            {component === 'phoneNumber' && (
                <PhoneInput
                    {...field}
                    id={props.id}
                    name={props.name}
                    international
                    countryCallingCodeEditable={true}
                    defaultCountry="AE"
                    value={field.value as PhoneType}
                    onChange={handlePhoneChange}
                    className={inputStyles}
                    maxLength="20"
                    style={getErrorInputStyles(meta.error)}
                />
            )}
        </>
    );
};
