import React, { useState } from 'react';
import {
    AdvancedMarker,
    APIProvider,
    InfoWindow,
    Map,
    useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';

import { useResizeButtons } from 'components/customMap/customMap.hooks';
import { ILocationData } from 'components/customMap/customMap.utils';

import styles from 'components/customMap/customMap.module.css';

export const CustomMap = ({
    position,
    tooltipText,
    tooltipWidth,
    zoomIn,
}: ILocationData) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [infowindowOpen, setInfowindowOpen] = useState(true);

    // restyle of native InfoWindow close button from google maps
    useResizeButtons();
    const handleInfowindowOpenClose = () => {
        setInfowindowOpen(!infowindowOpen);
    };

    return (
        <div style={{ width: '100%', height: '290px' }}>
            <APIProvider apiKey="AIzaSyCyrHzcfsScPuUaAVT98sGGTyZexyKU5Vk">
                <Map
                    mapId={tooltipText}
                    defaultZoom={zoomIn}
                    defaultCenter={position}
                    gestureHandling="greedy"
                    disableDefaultUI={true}
                >
                    <>
                        <AdvancedMarker
                            ref={markerRef}
                            onClick={handleInfowindowOpenClose}
                            position={position}
                        />
                        {infowindowOpen && (
                            <InfoWindow
                                pixelOffset={[0, -2]}
                                anchor={marker}
                                maxWidth={Number(tooltipWidth)}
                                onCloseClick={handleInfowindowOpenClose}
                                className={styles.tooltiptext}
                            >
                                <span
                                    style={{
                                        fontSize: '17px',
                                        lineHeight: '24px',
                                        fontFamily: 'Krub-Medium, sans-serif',
                                        overflowY: 'hidden',
                                        height: '100%',
                                        display: 'flex',
                                        textAlign: 'center',
                                    }}
                                >
                                    {tooltipText}
                                </span>
                            </InfoWindow>
                        )}
                    </>
                </Map>
            </APIProvider>
        </div>
    );
};
