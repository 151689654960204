import React from 'react';
import { createPortal } from 'react-dom';

import { AnimatedPart } from 'components/animatedPart/AnimatedPart';

import styles from 'components/popup/popup.module.css';

interface IPopupProps {
    isOpen: boolean;
    onClose: () => void;
    children: JSX.Element;
}

export const Popup = ({ isOpen, onClose, children }: IPopupProps) => {
    if (!isOpen) return null;

    return createPortal(
        <div className={styles.backdrop} onClick={onClose}>
            <AnimatedPart className={styles.popup__animated}>
                <div
                    className={styles.popup}
                    onClick={e => e.stopPropagation()}
                >
                    {children}
                </div>
            </AnimatedPart>
        </div>,
        document.body
    );
};
