export const ArrowDown = () => {
    return (
        <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="arrowDownT">Arrow down</title>
            <desc id="arrowDownD">A vector illustration of an arrow down</desc>
            <g clipPath="url(#a)">
                <path
                    d="M11.5-1v24m0 0L1.915 13m9.583 10 9.584-10"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h23v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};
