import React from 'react';

export const StatusErrorIcon = () => {
    return (
        <svg
            width="25"
            height="25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="statusErrorIconT">Status Error Icon</title>
            <desc id="statusErrorIconD">
                A vector illustration of a Status Error icon
            </desc>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.543 24.03c3.082-.03 6.134-1.31 8.328-3.484 2.607-2.582 3.949-6.401 3.48-10.048C23.55 4.248 18.425.008 12.467.008c-4.527 0-8.927 2.839-10.857 6.955-1.477 3.15-1.48 6.955 0 10.112 1.916 4.087 6.244 6.91 10.78 6.954h.154Zm-.141-2.002c-4.604-.045-8.9-3.614-9.755-8.19-.601-3.225.536-6.716 2.903-9.007 2.765-2.675 7.11-3.571 10.723-2.055 3.233 1.357 5.651 4.507 6.098 7.978.382 2.974-.683 6.092-2.776 8.24-1.836 1.883-4.41 3.008-7.065 3.034h-.128ZM6.21 16.883l4.867-4.867-4.872-4.872 1.401-1.402 4.872 4.872 5.04-5.039 1.404 1.404-5.04 5.04 5.05 5.048-1.402 1.402-5.05-5.05-4.867 4.868-1.404-1.404Z"
                fill="#D75A4A"
            />
        </svg>
    );
};
