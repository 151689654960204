import React from 'react';
import { Element } from 'react-scroll';

import { AnimatedPart } from 'components/animatedPart/AnimatedPart';
import { AboutUsMedia } from 'sections/aboutUs/AboutUsMedia';

import styles from 'sections/aboutUs/aboutUs.module.css';
import sharedStyles from 'sharedStyles/sharedStyles.module.css';

export const AboutUs = () => {
    return (
        <section className={styles.aboutUs}>
            <Element name="aboutUs" className={styles.aboutUs__container}>
                <AboutUsMedia />
                <div className={styles.aboutUs__info}>
                    <div
                        className={`${sharedStyles.titleBox} ${styles.info__titleBox}`}
                    >
                        <h3 className={styles.info__title}>About us</h3>
                        <AnimatedPart isDecoration={true}>
                            <div
                                className={sharedStyles.title__decoration}
                            ></div>
                        </AnimatedPart>
                    </div>

                    <AnimatedPart>
                        <p
                            className={`${styles.info__text} ${styles.text_mb18}`}
                        >
                            At Sinergetic, we believe in the power of synergy
                            and aim for big results with a small team of
                            dedicated seasoned professionals. We bring together
                            decades of experience and a deep understanding of
                            the market to offer unparalleled service and
                            expertise. While our current focus is solely on
                            bunker trading and brokering, our background in oil
                            refining and physical supply has equipped us with
                            extensive industry connections and insights.
                        </p>
                    </AnimatedPart>

                    <AnimatedPart delay="0.2s">
                        <p className={styles.info__text}>
                            Our strong relationships with shipowners and
                            charterers allow us to provide customized solutions
                            tailored to meet the unique needs of each customer.
                            We leverage our rich background and vast network to
                            ensure reliable, efficient, and high-quality
                            services that support the smooth operation of your
                            maritime activities.
                        </p>
                    </AnimatedPart>
                </div>
            </Element>
        </section>
    );
};
