import React from 'react';
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
} from 'react-simple-maps';

import { markers } from 'sections/weOperateIn/constants';
import { mapData } from 'sections/weOperateIn/data';

import styles from 'sections/weOperateIn/mapWithPins.module.css';

export const MapWithPins = () => {
    const getAnimationDelay = (index: number): string => {
        const delay = 0.1;
        return index * delay + 's';
    };

    return (
        <div className={styles.mapContainer}>
            <ComposableMap>
                <Geographies geography={mapData}>
                    {({ geographies }) =>
                        geographies.map(geo => (
                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                className={styles.geography}
                            />
                        ))
                    }
                </Geographies>
                {markers.map(({ name, coordinates }, index) => (
                    <Marker key={name} coordinates={coordinates}>
                        <g
                            clipPath="url(#a)"
                            className={styles.pin}
                            style={{ animationDelay: getAnimationDelay(index) }}
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12 6.974a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm0 6a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5Zm0-12.75a9 9 0 0 0-9 9c0 3.763 7.504 15.008 9 15 1.473.008 9-11.288 9-15a9 9 0 0 0-9-9Z"
                                fill="#FFE607"
                                stroke="black"
                                strokeWidth="0.1"
                            />
                        </g>
                    </Marker>
                ))}
            </ComposableMap>
        </div>
    );
};
