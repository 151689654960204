import React, { useState } from 'react';
import { Element } from 'react-scroll';

import { AnimatedPart } from 'components/animatedPart/AnimatedPart';
import { Popup } from 'components/popup/Popup';
import { ServiceOption } from 'sections/services/ServiceOption';

import styles from 'sections/services/services.module.css';
import sharedStyles from 'sharedStyles/sharedStyles.module.css';

export const Services = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handlePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    return (
        <section className={styles.services}>
            <Element name="services" className={styles.services__container}>
                <div className={styles.services__info}>
                    <div
                        className={`${sharedStyles.titleBox} ${styles.services__titleBox}`}
                    >
                        <h3 className={styles.services__title}>services</h3>
                        <AnimatedPart isDecoration={true}>
                            <div
                                className={sharedStyles.title__decoration}
                            ></div>
                        </AnimatedPart>
                    </div>
                    <p className={styles.services__text}>
                        Experience personalized services
                        <br /> forging lasting relationships
                    </p>
                </div>
                <div className={styles.services__media}>
                    <div className={styles.services__mediaContainer}>
                        <ServiceOption handlePopup={handlePopup} />
                    </div>
                </div>
                <Popup isOpen={isPopupOpen} onClose={handlePopup}>
                    <ServiceOption isPopup={true} handlePopup={handlePopup} />
                </Popup>
            </Element>
        </section>
    );
};
