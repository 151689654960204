export const Cross = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="crossT">Cross</title>
            <desc id="crossD">A vector illustration of a cross</desc>
            <path
                d="m3.36 20.467 17.28-17.28M3.36 3.187l17.28 17.28"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
