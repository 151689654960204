import React from 'react';
import { Link } from 'react-scroll';

import { Location } from 'components/location/Location';
import { Logo } from 'components/vectors/Logo';
import { LogoMobile } from 'components/vectors/LogoMobile';
import { IS_MOBILE } from 'constants/media';

import styles from 'components/footer/footer.module.css';

export const Footer = () => {
    const mainColor = '#fff';

    return (
        <footer className={styles.footer}>
            <Link
                className={styles.footer__logo}
                to="home"
                smooth={true}
                duration={800}
                spy={true}
            >
                {IS_MOBILE ? <LogoMobile /> : <Logo fill={mainColor} />}
            </Link>

            <div className={styles.footer__location}>
                <Location color={mainColor} />
            </div>
        </footer>
    );
};
