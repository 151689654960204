import React from 'react';
import { Element, Link } from 'react-scroll';

import { AnimatedPart } from 'components/animatedPart/AnimatedPart';
import { MapWithPins } from 'sections/weOperateIn/MapWithPins';

import styles from 'sections/weOperateIn/weOperateIn.module.css';
import sharedStyles from 'sharedStyles/sharedStyles.module.css';

export const WeOperateIn = () => {
    return (
        <section className={styles.weOperateIn}>
            <Element
                name="weOperateIn"
                className={styles.weOperateIn__container}
            >
                <div className={styles.info}>
                    <div
                        className={`${sharedStyles.titleBox} ${styles.info__titleBox}`}
                    >
                        <h3 className={styles.info__title}>
                            Ports and Cities We Operate In
                        </h3>
                        <AnimatedPart isDecoration={true}>
                            <div
                                className={`${sharedStyles.title__decoration} ${sharedStyles.title__decoration_white}`}
                            ></div>
                        </AnimatedPart>
                    </div>
                    <p className={styles.info__text}>
                        At this stage, our company is engaged in bunker trading
                        worldwide. No matter where your vessel is located, we
                        are prepared to provide you with high-quality services
                        and timely fuel delivery anywhere in the world. Our
                        global partnerships and logistical capabilities enable
                        us to efficiently and promptly serve clients on an
                        international scale.
                    </p>

                    <Link to="contactUsForm" smooth={true} duration={800}>
                        <button className={styles.info__button}>
                            contact us
                        </button>
                    </Link>
                </div>
                <MapWithPins />
            </Element>
        </section>
    );
};
