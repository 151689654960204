import { IS_MOBILE, IS_TABLET } from 'constants/media';

export interface ILocationData {
    position: {
        lat: number;
        lng: number;
    };
    tooltipText: string;
    tooltipWidth?: string;
    zoomIn: number;
}

export const getDubaiData = (): ILocationData => {
    const position = { lat: 25.133241, lng: 55.383763 };
    const tooltipText = 'Dubai Silicon Oasis';
    const tooltipWidth = '200';
    const zoomIn = 12;

    return { position, tooltipText, tooltipWidth, zoomIn };
};

export const getIstanbulData = (): ILocationData => {
    const position = {
        lat: 41.04099,
        lng: 29.04961,
    };
    const tooltipText =
        'Burhaniye, Resmi Efendi sk. №46, 34676 Uskudar/Istanbul, Turkiye';
    const tooltipWidth = IS_TABLET || IS_MOBILE ? '230' : '320';
    const zoomIn = 11;

    return { position, tooltipText, tooltipWidth, zoomIn };
};
