import React, { useState } from 'react';

import { AnimatedPart } from 'components/animatedPart/AnimatedPart';
import { ArrowRight } from 'components/vectors/ArrowRight';
import { Cross } from 'components/vectors/Cross';

import styles from 'sections/services/services.module.css';

interface IServiceOption {
    isPopup?: boolean;
    handlePopup: () => void;
}

export const ServiceOption = ({ isPopup, handlePopup }: IServiceOption) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const handleVideoLoad = () => {
        setIsVideoLoaded(true);
    };

    return (
        <div>
            {isPopup && (
                <button
                    className={styles.services__closePopup}
                    onClick={handlePopup}
                >
                    <Cross />
                </button>
            )}

            <div
                className={`${styles.services__gifBox} ${isPopup ? `${styles.services__gifBox} ${styles.services__gifBox_small}` : null}`}
            >
                {!isVideoLoaded && (
                    <div className={styles.services__videoLoading}></div>
                )}
                <video
                    controls={false}
                    muted
                    autoPlay
                    playsInline
                    loop
                    className={styles.services__gif}
                    onLoadedData={handleVideoLoad}
                >
                    <source
                        src="https://sni-general.s3.amazonaws.com/services/video.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support video
                </video>
            </div>

            <div className={styles.services__gifDesc}>
                <AnimatedPart delay="0.2s">
                    <h3 className={styles.gifDesc__title}>fuel supply</h3>
                </AnimatedPart>

                {isPopup ? (
                    <AnimatedPart delay="0.4s">
                        <p className={styles.gifDesc__text}>
                            At <b>SINERGETIC NATURALIS INDUSTRIA</b>, we provide
                            reliable and efficient fuel supply services to
                            ensure your operations run smoothly. Our
                            high-quality fuel is sourced from trusted suppliers
                            and delivered promptly to meet your needs.
                        </p>
                    </AnimatedPart>
                ) : (
                    <button
                        className={styles.gifDesc__readMoreBox}
                        onClick={handlePopup}
                    >
                        <div className={styles.gifDesc__readMore}>
                            Read more
                        </div>
                        <div className={styles.gifDesc__arrowRight}>
                            <ArrowRight />
                        </div>
                    </button>
                )}
            </div>
        </div>
    );
};
