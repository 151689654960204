import { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import { ErrorMessage, Form, Formik } from 'formik';

import { useContactFormSchema } from 'components/contactForm/contactFormSchema';
import { CustomField } from 'components/contactForm/CustomField';
import { Notification } from 'components/notification/Notification';
import { getNotificationTextData } from 'components/notification/notification.util';
import { StatusErrorIcon } from 'components/vectors/StatusErrorIcon';
import { StatusOkIcon } from 'components/vectors/StatusOkIcon';

import 'react-phone-number-input/style.css';
import styles from 'components/contactForm/contactForm.module.css';

type TFormData = Record<string, unknown> & {
    fullName: string;
    companyName: string;
    phoneNumber: string;
    email: string;
    message: string;
};

export interface INotificationText {
    titleText: string;
    descText: string;
}

const apiUrl =
    'https://ro5phlscwe.execute-api.us-east-1.amazonaws.com/fetchEmailJsKeys';

const ContactForm = () => {
    const [emailKeys, setEmailKeys] = useState<{
        publicKey: string;
        serviceId: string;
        templateId: string;
    } | null>(null);
    const [fetchError, setFetchError] = useState<string | null>(null);

    const contactFormSchema = useContactFormSchema();
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [isMessageSentSuccessfully, setIsMessageSentSuccessfully] =
        useState(false);

    const notificationTextData = getNotificationTextData();

    const [notificationText, setNotificationText] = useState<INotificationText>(
        notificationTextData.success
    );

    useEffect(() => {
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setEmailKeys({
                    publicKey: data.EMAIL_PUBLIC_KEY,
                    serviceId: data.EMAIL_SERVICE_ID,
                    templateId: data.EMAIL_TEMPLATE_ID,
                });
                emailjs.init(data.EMAIL_PUBLIC_KEY);
            })
            .catch(error => {
                console.error('Error fetching keys:', error);
                setFetchError('Failed to fetch EmailJS keys');
            });
    }, []);

    const sendEmail = (
        formData: TFormData,
        setSubmitting: (_isSubmitting: boolean) => void
    ) => {
        if (!emailKeys) {
            setNotificationText(notificationTextData.emailConnectionError);
            setIsNotificationOpen(!isNotificationOpen);
            setSubmitting(false);
            return;
        }

        emailjs
            .send(
                emailKeys.serviceId,
                emailKeys.templateId,
                formData,
                emailKeys.publicKey
            )
            .then(() => {
                setNotificationText(notificationTextData.success);
                setIsNotificationOpen(!isNotificationOpen);
                setIsMessageSentSuccessfully(true);
            })
            .catch(() => {
                setNotificationText(notificationTextData.error);
                setIsNotificationOpen(!isNotificationOpen);
                setIsMessageSentSuccessfully(false);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const onSubmit = (
        values: TFormData,
        {
            setSubmitting,
            resetForm,
        }: {
            setSubmitting: (_isSubmitting: boolean) => void;
            resetForm: () => void;
        }
    ) => {
        setSubmitting(true);
        sendEmail(values, setSubmitting);

        resetForm();
    };

    const getStatusIcon = isMessageSentSuccessfully ? (
        <StatusOkIcon />
    ) : (
        <StatusErrorIcon />
    );

    if (fetchError)
        return <p className={styles.apiErrorMessage}>{fetchError}</p>;

    return (
        <div className={styles.formContainer}>
            <h5 className={styles.formTitle}>Get a free consultation</h5>
            <Formik
                initialValues={{
                    fullName: '',
                    companyName: '',
                    phoneNumber: '',
                    email: '',
                    message: '',
                }}
                validationSchema={contactFormSchema}
                onSubmit={onSubmit}
                validateOnBlur={true}
            >
                {({ isSubmitting }) => (
                    <Form className={styles.form}>
                        <div className={styles.inputGroup}>
                            <CustomField
                                label="Full name*"
                                id="fullName"
                                name="fullName"
                                type="text"
                                placeholder="Jane Smith"
                                component="input"
                            />

                            <ErrorMessage
                                name="fullName"
                                component="div"
                                className={styles.error}
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <CustomField
                                label="Company name (optional)"
                                id="companyName"
                                name="companyName"
                                type="text"
                                placeholder=""
                                component="input"
                            />

                            <ErrorMessage
                                name="companyName"
                                component="div"
                                className={styles.error}
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <CustomField
                                label="Phone number*"
                                id="phoneNumber"
                                name="phoneNumber"
                                component="phoneNumber"
                            />

                            <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className={styles.error}
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <CustomField
                                label="Email*"
                                id="email"
                                name="email"
                                type="email"
                                placeholder="email@gmail.com"
                                component="input"
                            />

                            <ErrorMessage
                                name="email"
                                component="div"
                                className={styles.error}
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <CustomField
                                label="Message*"
                                id="message"
                                name="message"
                                placeholder="Your request"
                                component="textarea"
                            />

                            <ErrorMessage
                                name="message"
                                component="div"
                                className={styles.error}
                            />
                        </div>

                        <button
                            type="submit"
                            disabled={isSubmitting || !emailKeys}
                            className={styles.submitButton}
                        >
                            Leave a request
                        </button>
                    </Form>
                )}
            </Formik>

            <Notification
                isNotificationOpen={isNotificationOpen}
                setIsNotificationOpen={setIsNotificationOpen}
                icon={getStatusIcon}
                notificationText={notificationText}
            />
        </div>
    );
};

export default ContactForm;
