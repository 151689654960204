import React from 'react';
import { Element } from 'react-scroll';

import { AnimatedPart } from 'components/animatedPart/AnimatedPart';
import ContactForm from 'components/contactForm/ContactForm';
import { CustomMap } from 'components/customMap/CustomMap';
import {
    getDubaiData,
    getIstanbulData,
} from 'components/customMap/customMap.utils';

import styles from 'sections/contactUs/contactUs.module.css';
import sharedStyles from 'sharedStyles/sharedStyles.module.css';

export const ContactUs = () => {
    const dubaiData = getDubaiData();
    const istanbulData = getIstanbulData();

    return (
        <section className={styles.contactUs}>
            <Element name="contactUs" className={styles.contactUs__container}>
                <div
                    className={`${sharedStyles.titleBox} ${styles.contactUs__titleBox}`}
                >
                    <h3 className={styles.contactUs__title}>Contact Us</h3>
                    <AnimatedPart isDecoration={true}>
                        <div className={sharedStyles.title__decoration}></div>
                    </AnimatedPart>
                </div>

                <div className={styles.contactUs__content}>
                    <div className={styles.contactUs__maps}>
                        <div className={styles.maps}>
                            <div className={styles.maps__dubai}>
                                <h3>we are in dubai</h3>
                                <CustomMap {...dubaiData} />
                            </div>
                            <div className={styles.maps__turkey}>
                                <h3>we are in istanbul</h3>
                                <CustomMap {...istanbulData} />
                            </div>
                        </div>
                    </div>

                    <Element
                        name="contactUsForm"
                        className={styles.contactUs__form}
                    >
                        <ContactForm />
                    </Element>
                </div>
            </Element>
        </section>
    );
};
