export const SmallShip = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="smallShipT">Small boat</title>
            <desc id="smallShipD">A vector illustration of a small boat</desc>
            <path
                d="M12.174 13.881H8a1.038 1.038 0 0 1-1.037-1.043V8.664c0-.577.46-1.044 1.037-1.044h4.174c.576 0 1.043.468 1.043 1.044v4.174c0 .576-.467 1.043-1.043 1.043Z"
                fill="#8C6D50"
            />
            <path
                d="M16.348 13.881h-4.174a1.038 1.038 0 0 1-1.037-1.043V6.577c0-.576.46-1.043 1.037-1.043h4.174c.576 0 1.043.467 1.043 1.043v6.26c0 .577-.467 1.044-1.043 1.044Z"
                fill="#8C6D50"
            />
            <path
                d="M16.347 5.533h-4.174v8.348h4.174c.576 0 1.044-.467 1.044-1.043V6.577c0-.576-.468-1.044-1.044-1.044Z"
                fill="#765B43"
            />
            <path
                d="M18.435 11.794H5.913a3.134 3.134 0 0 0-3.13 3.13v6.261c0 .577.467 1.044 1.043 1.044h15.13l2.609-5.217v-2.087a3.134 3.134 0 0 0-3.13-3.13Z"
                fill="#E2E7EC"
            />
            <path
                d="M18.434 11.794h-6.26V22.23h6.782l2.608-5.217v-2.087a3.134 3.134 0 0 0-3.13-3.13Z"
                fill="#CCD5E0"
            />
            <path
                d="M31.802 16.401a1.044 1.044 0 0 0-.846-.433H20.52c-.434 0-.824.27-.977.677l-1.31 3.497H1.043a1.043 1.043 0 0 0-.99 1.373l2.324 6.975h25.966l3.602-11.148a1.043 1.043 0 0 0-.144-.94Z"
                fill="#78A8E2"
            />
            <path
                d="M30.956 15.968H20.52c-.435 0-.824.27-.977.677l-1.31 3.497h-6.06v8.348h16.17l3.601-11.148a1.043 1.043 0 0 0-.99-1.374Z"
                fill="#6B96C8"
            />
            <path
                d="M10.087 18.066H8a1.043 1.043 0 0 1 0-2.087h2.087a1.043 1.043 0 1 1 0 2.087Z"
                fill="#8C6D50"
            />
            <path
                d="M16.348 18.066H14.26a1.043 1.043 0 0 1 0-2.087h2.087a1.043 1.043 0 0 1 0 2.087Z"
                fill="#765B43"
            />
            <path
                d="M31.992 29.575c-.07.577-.591.98-1.161.911a8.189 8.189 0 0 1-4.063-1.676c-3.012 2.358-7.422 2.358-10.435 0a8.322 8.322 0 0 1-4.16 1.697c-.347.049-.702.07-1.057.07-1.857 0-3.708-.592-5.217-1.767a8.202 8.202 0 0 1-4.09 1.683 1.041 1.041 0 0 1-1.155-.918 1.04 1.04 0 0 1 .911-1.155 6.145 6.145 0 0 0 3.596-1.739c.39-.39 1.086-.396 1.475 0 1.454 1.454 3.569 2.017 5.538 1.704 1.273-.201 2.483-.765 3.422-1.704a1.037 1.037 0 0 1 1.475 0c2.386 2.386 6.574 2.386 8.96 0a1.037 1.037 0 0 1 1.474 0 6.13 6.13 0 0 0 3.576 1.74c.57.062.98.583.911 1.154Z"
                fill="#A7E2E2"
            />
            <path
                d="M25.217 22.24H23.13a1.044 1.044 0 0 1 0-2.087h2.087a1.043 1.043 0 0 1 0 2.087Z"
                fill="#537DAF"
            />
            <path
                d="M3.826 5.533A3.545 3.545 0 0 1 .87 3.952a1.043 1.043 0 1 1 1.736-1.158c.277.415.721.653 1.219.653.498 0 .942-.238 1.219-.653.66-.99 1.765-1.372 2.955-1.372 1.19 0 2.294.382 2.955 1.372a1.043 1.043 0 1 1-1.737 1.158A1.444 1.444 0 0 0 8 3.3c-.498 0-.943.238-1.219.652a3.545 3.545 0 0 1-2.955 1.581Z"
                fill="#E8C870"
            />
            <path
                d="M31.992 29.575c-.07.577-.591.98-1.162.911a8.186 8.186 0 0 1-4.062-1.676c-3.012 2.358-7.422 2.358-10.435 0a8.321 8.321 0 0 1-4.16 1.697v-2.122c1.273-.201 2.484-.765 3.423-1.704a1.037 1.037 0 0 1 1.475 0c2.386 2.386 6.573 2.386 8.96 0a1.037 1.037 0 0 1 1.474 0 6.13 6.13 0 0 0 3.576 1.74c.57.062.98.584.911 1.154Z"
                fill="#8AC8C8"
            />
        </svg>
    );
};
