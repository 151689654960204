import { INotificationText } from 'components/contactForm/ContactForm';
import { Cross } from 'components/vectors/Cross';

import styles from 'components/notification/notification.module.css';

interface INotificationPros {
    icon: JSX.Element;
    isNotificationOpen: boolean;
    setIsNotificationOpen: (_val: boolean) => void;
    notificationText?: INotificationText;
}

export const Notification = ({
    icon,
    isNotificationOpen,
    setIsNotificationOpen,
    notificationText,
}: INotificationPros) => {
    const closeNotification = () => {
        setIsNotificationOpen(!isNotificationOpen);
    };

    if (!isNotificationOpen) {
        return null;
    }

    return (
        <div className={styles.notification}>
            <div
                className={styles.notification__closeIcon}
                onClick={closeNotification}
            >
                <Cross />
            </div>

            <div className={styles.notification__statusIcon}>{icon}</div>

            <p className={styles.notification__title}>
                {notificationText?.titleText}
            </p>
            <p className={styles.notification__desc}>
                {notificationText?.descText}
            </p>
        </div>
    );
};
